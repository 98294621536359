// 工会+团支部
<template>
  <div class="partyCulture-underWork">
    <div class="underWork-images justify-between back-border">
      <el-image class="underWork-images-big" :src="imgUrl"></el-image>
      <InfoImg @setData="setData" field="logo" @previewImg="previewImg" :isActive="true" />
    </div>
    <Info v-if="isData.length" :isData="isData" @setData="setData" class="underWork-info" />
  </div>
</template>

<script>
import Info from '@/components/Info'
import InfoImg from '@/components/InfoImg'
export default {
  components: {
    Info,
    InfoImg
  },
  data () {
    return {
      isData: [],
      imgUrl: ''
    };
  },
  methods: {
    setData (data) {
      this.isData = data
    },
    previewImg (imgUrl) {
      this.imgUrl = imgUrl
    }
  },

};
</script>

<style scoped lang="less">
.underWork-images {
  margin-bottom: 32px;
  .underWork-images-big {
    width: 46%;
    height: 290px;
    margin-right: 48px;
    border-radius: 10px;
  }
  /deep/ .infoImg-list {
    flex: 1;
    .infoImg-item {
      margin: 7px 0;
      .el-image {
        max-width: 130px;
        border-radius: 8px;
      }
    }
    .infoImg-item-active {
      position: relative;
      .item-active-box {
        position: absolute;
        max-width: 124px;
        width: 124px;
        height: 94%;
        top: 0;
        left: 0;
        background-color: rgba(244, 255, 255, 0.6);
        border: 3px solid #ffd381;
        border-radius: 8px;
      }
    }
  }
}
/deep/ .underWork-info {
  p {
    line-height: 30px;
  }
  h2 {
    font-size: 24px;
    color: #333333;
    margin-bottom: 10px;
  }
}
</style>
